import { QueryClient, QueryCache } from "@tanstack/react-query"
import { ClientError } from "graphql-request"

export function extractErrorCode(error) {
  if (error instanceof ClientError) {
    return error.response?.errors?.[0].message
  }
  return error.message
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache(),
})
